.RefButton {
  /* display: block; */
  /* margin: 0 5px; */
  /* padding: 10px 15px; */
  text-decoration: none;
  background-color: transparent;
  color: #000;
  border-radius: 3px;
  font-weight: 700;
  font-size: calc(0.4rem + 0.7vw);
}

@media screen and (max-width: 700px) {
  .RefButton {
    text-decoration: none;
    background-color: transparent;
    color: #000;
    border-radius: 3px;
    font-weight: 700;
    font-size: calc(0.8rem + 0.7vw);
  }
}

.RefButton:hover {
  background-color: transparent;
}

.HomeButton {
  text-decoration: none;
  color: #212b36;
  font-weight: bold;
  font-size: calc(0.9rem + 0.5vw);
}
