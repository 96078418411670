html {
  scroll-behavior: smooth;
}
* {
  word-spacing: 3px;
}
.notion-toggle > summary {
  /* display: none; */
}
.notion-toggle .notion-h2 {
  font-size: calc(1.2rem + 0.5vw);
}

.notion-text {
  line-height: 1.8;
  font-size: calc(0.8rem + 0.5vw);
  margin-top: 20;
  top: 20;
  position: relative;
  top: 12px;
}

.notion-text b {
  font-weight: 700;
}

.active {
  color: #2065d1 !important;
}

.notion-link {
  text-decoration: none !important;
  font-weight: 700 !important;
  color: #2065d1 !important;
}
.notion-link:hover {
  text-decoration: underline !important;
}

/* Students */
.notion-asset-wrapper {
  display: inline-block;
}
.notion-image-inset {
  display: flex;
}

/* Base */

#Base .notion-quote {
  display: none;
}

#Base .notion-page-header {
  display: none;
}
#Base .notion-title {
  display: none;
}
#Base .notion-nav-title {
  display: none;
}
#Base .notion-page-offset {
  margin-top: 0;
}

#Base .notion-row {
  padding: 0;
}
#Base .notion-row .notion-column {
  padding: 0;
}

#Base .notion-row .notion-column .notion-h1 {
  font-size: calc(1.3rem + 0.2vw);
  margin-top: 35px;
  word-wrap: break-word;
  padding-top: 75px;
}
#Base .notion-row .notion-column .notion-h2 {
  font-size: calc(1rem + 0.3vw);
  word-break: keep-all;
}

#Base .notion-row .notion-column .notion-h3 {
  font-size: calc(1rem + 0.2vw);
}

#Base .notion-row .notion-column .notion-text {
  margin: 8px 0 50px 0;
  word-break: keep-all;
  /* padding-top: 80px; */
}

@media screen and (min-width: 700px) {
  #Base .notion-row .notion-column .notion-blank {
    padding-top: 70px;
  }
}

#Base .notion-blue {
  color: #2065d1;
}

@media screen and (min-width: 700px) {
  #Base iframe {
    max-width: 560px;
    height: 315px;
    min-width: auto;
  }
  #Base > div > main > div:nth-child(10) > div:nth-child(3) > figure > div {
    margin-bottom: -97px;
  }
}

@media screen and (max-width: 700px) {
  #Base iframe {
    min-height: 7.2rem;
  }
}

@media screen and (max-width: 700px) {
  #Base .notion-row .notion-column .notion-blank {
    display: none;
  }
  #new-회사소개
    > div
    > div
    > div
    > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-3.css-iv7vbz-MuiGrid-root
    > div
    > div
    > h6
    > div
    > p {
    width: 200px;
    justify-content: center;
    display: flex;
    left: 0;
    right: 0;
    margin: 20px auto 0 auto;
  }
  #new-About
    > div
    > div
    > div
    > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-3.css-iv7vbz-MuiGrid-root
    > div
    > div
    > h6
    > div
    > p {
    width: 200px;
    justify-content: center;
    display: flex;
    left: 0;
    right: 0;
    margin: auto;
  }
}

#Base {
  max-width: inherit;
}

#Base .notion-toggle {
  display: none;
}

#Base #News {
  padding-top: 80px;
}

#Base
  > div
  > main
  > div:nth-child(n + 63):nth-child(-n + 81)
  > div:nth-child(1)
  > figure
  > div
  > img {
  min-width: 210px;
  min-height: 160px;
}

@media screen and (max-width: 700px) {
  #Base > div > main > figure:nth-child(9) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }

  #Base > div > main > figure:nth-child(17) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }
  #Base > div > main > figure:nth-child(20) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }
  #Base > div > main > figure:nth-child(25) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }

  #Base > div > main > figure:nth-child(29) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }

  #Base > div > main > figure:nth-child(29) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }
  #Base > div > main > figure:nth-child(35) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }
  #Base > div > main > figure:nth-child(43) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }

  #Base > div > main > figure:nth-child(47) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }

  #Base > div > main > figure:nth-child(49) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }
  #Base > div > main > figure:nth-child(56) > div > img {
    min-width: 125%;
    min-height: 125%;
    left: -35px;
    top: -30px;
  }
}
/* #PrivacyBase {
  display: none;
} */

#LabBase .notion {
  display: none;
}

/* profile 사진 텍스트 */

@media screen and (max-width: 700px) {
  #lab-text {
    font-size: 0.8rem;
    word-wrap: break-word;
  }
  #lab-text #Students figure {
    margin-bottom: -81%;
  }
  #lab-text #Students figure .notion-image-inset {
    width: 50%;
    height: 50%;
  }
}

.fullScreen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* 이용약관 */
#termsite-main {
  font-size: 1rem;
}
